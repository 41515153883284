var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            size: _vm.size,
            "label-width": _vm.labelWidth
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateSubmit($event)
            }
          }
        },
        [
          _c("c-form-row", {
            attrs: { list: _vm.models, readOnly: _vm.readOnly },
            scopedSlots: _vm._u([
              {
                key: "jobTitleName",
                fn: function(scope) {
                  return [
                    _c(
                      "c-box",
                      _vm._b(
                        { staticStyle: { margin: "0 50px 25px 50px" } },
                        "c-box",
                        scope.data.itemProps,
                        false
                      ),
                      [
                        _vm._v(
                          " 当前科室主任: " +
                            _vm._s(_vm.form.jobTitleName) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "jobTitle",
                fn: function(scope) {
                  return [
                    _c(
                      "c-box",
                      _vm._b({}, "c-box", scope.data.itemProps, false),
                      [
                        _c("div", { staticStyle: { margin: "10px 50px" } }, [
                          _vm._v(
                            '是否设置"' + _vm._s(_vm.form.name) + '"为科室主任'
                          )
                        ]),
                        _c(
                          "c-box",
                          {
                            staticStyle: {
                              margin: "0 50px",
                              "margin-top": "20px"
                            }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.form.jobTitle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "jobTitle", $$v)
                                  },
                                  expression: "form.jobTitle"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("医生")
                                ]),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("主任")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.validateSubmit }
            },
            [_vm._v("保 存")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }