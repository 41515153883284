/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'jobTitleName',
    itemProps: {
      label: '当前科室主任：' },

    component: {
      name: 'text' } },


  {
    kname: 'jobTitle',
    itemProps: {
      label: '职称：' } }];});




// form 默认值
export var deptFormOptions = function deptFormOptions() {return {
    li: [{}, {}, {}, {}, {}, {}, {}, {}] };};